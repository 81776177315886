// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import zhTranslation from './locales/zh/translation.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            zh: { translation: zhTranslation },
        },
        lng: navigator.language.startsWith('zh') ? 'zh' : 'en', // Detect language
        fallbackLng: 'en',
        interpolation: { escapeValue: false },
    });

export default i18n;